import React from 'react';

import {
    SvgIcon,
} from '@material-ui/core';



export const PRIMARY_COLOR = {
    '50': '#E5E7F2',
    '100': '#BDC4DD',
    '200': '#919DC7',
    '300': '#6575B1',
    '400': '#4458A0',
    '500': '#233A8F',
    '600': '#1F3487',
    '700': '#1A2C7C',
    '800': '#152572',
    '900': '#0C1860',
    'A100': '#94A1FF',
    'A200': '#6174FF',
    'A400': '#2E47FF',
    'A700': '#1430FF',
};

export const SECONDARY_COLOR = {
    '50': '#E5E7F2',
    '100': '#BDC4DD',
    '200': '#919DC7',
    '300': '#6575B1',
    '400': '#4458A0',
    '500': '#233A8F',
    '600': '#1F3487',
    '700': '#1A2C7C',
    '800': '#152572',
    '900': '#0C1860',
    'A100': '#94A1FF',
    'A200': '#6174FF',
    //    'A400': '#2E47FF',
    'A400': '#233A8F',
    'A700': '#1430FF',
};

// export const SECONDARY_COLOR = {
//     '50': '#FEFDE5',
//     '100': '#FCF9BD',
//     '200': '#FAF591',
//     '300': '#F8F165',
//     '400': '#F7EE44',
//     '500': '#F5EB23',
//     '600': '#F4E91F',
//     '700': '#F2E51A',
//     '800': '#F0E215',
//     '900': '#EEDD0C',
//     'A100': '#FFFFFF',
// //    'A200': '#FFFDE5',
// //    'A400': '#FFF9B2',
// //    'A700': '#FFF798',
//     'A200': '#FAF591',
//     'A400': '#F7EE44',
//     'A700': '#F7EE44',
// };

export const GOOGLE_RECAPTCHA_SITE_KEY = __GOOGLE_RECAPTCHA_SITE_KEY__;
export const GOOGLE_TAG_MANAGER_ARGS = {
    gtmId: __GOOGLE_TAG_MANAGER_ID__,
};
export const NEWSLETTER_SERVER_URL = __NEWSLETTER_SERVER_URL__;



/////////////////////////////////////////////////////////
//                                                     //
//          Configuration OpenStreetMap (OSM)          //
//                                                     //
/////////////////////////////////////////////////////////

export const OSM_MAP_NAME = "OpenStreetMap_France";

export const OSM_MARKER_ICON_RETINA_URL = "/static/img/leaflet/dist/images/marker-icon-2x.png";
export const OSM_MARKER_ICON_URL = "/static/img/leaflet/dist/images/marker-icon.png";
export const OSM_MARKER_SHADOW_URL = "/static/img/leaflet/dist/images/marker-shadow.png";



//////////////////////////////////////////////////////
//                                                  //
//          Bouton d'action flottant (FAB)          //
//                                                  //
//////////////////////////////////////////////////////

// Icones utilisées
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search';

// Liste des actions (= boutons) contenues dans le "FAB"
export const FAB_ACTIONS = [
    { icon: <MenuBookIcon color="primary" />, name: 'Ressource documentaire', url: "/ressource-documentaire/" },
    { icon: <SearchIcon color="primary" />, name: 'Rechercher', url: "/rechercher/" },
];



/////////////////////////////////////////////////////
//                                                 //
//          Icones utilisées dans le menu          //
//                                                 //
/////////////////////////////////////////////////////

export const MenuActualitesIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <g>
	        <path d="M9 42Q7.8 42 6.9 41.1Q6 40.2 6 39V9Q6 7.8 6.9 6.9Q7.8 6 9 6H32.1L42 15.9V39Q42 40.2 41.1 41.1Q40.2 42 39 42ZM9 39H39V17.55H30.45V9H9ZM13.95 33.45H34.05V30.45H13.95ZM13.95 17.55H24V14.55H13.95ZM13.95 25.5H34.05V22.5H13.95ZM9 9V17.55V9V17.55V39Z"/>
	    </g>
        </SvgIcon>
    );
};



export const MenuActionIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <g>
                <path d="M19.15 32.5 32.5 24 19.15 15.5ZM24 44Q19.9 44 16.25 42.425Q12.6 40.85 9.875 38.125Q7.15 35.4 5.575 31.75Q4 28.1 4 24Q4 19.9 5.575 16.25Q7.15 12.6 9.875 9.875Q12.6 7.15 16.25 5.575Q19.9 4 24 4Q28.1 4 31.75 5.575Q35.4 7.15 38.125 9.875Q40.85 12.6 42.425 16.25Q44 19.9 44 24Q44 28.1 42.425 31.75Q40.85 35.4 38.125 38.125Q35.4 40.85 31.75 42.425Q28.1 44 24 44ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24ZM24 41Q31 41 36 36Q41 31 41 24Q41 17 36 12Q31 7 24 7Q17 7 12 12Q7 17 7 24Q7 31 12 36Q17 41 24 41Z"/>
	    </g>
        </SvgIcon>
    );
};



export const MenuAssociationIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <g>
	        <path d="M1.9 40V35.3Q1.9 33.55 2.8 32.125Q3.7 30.7 5.3 30Q8.95 28.4 11.875 27.7Q14.8 27 17.9 27Q21 27 23.9 27.7Q26.8 28.4 30.45 30Q32.05 30.7 32.975 32.125Q33.9 33.55 33.9 35.3V40ZM31.1 26.85Q34.55 27.25 37.6 28.025Q40.65 28.8 42.55 29.8Q44.2 30.75 45.15 32.15Q46.1 33.55 46.1 35.3V40H36.9V35.3Q36.9 32.15 35.3 30.125Q33.7 28.1 31.1 26.85ZM17.9 23.95Q14.6 23.95 12.5 21.85Q10.4 19.75 10.4 16.45Q10.4 13.15 12.5 11.05Q14.6 8.95 17.9 8.95Q21.2 8.95 23.3 11.05Q25.4 13.15 25.4 16.45Q25.4 19.75 23.3 21.85Q21.2 23.95 17.9 23.95ZM35.9 16.45Q35.9 19.75 33.8 21.85Q31.7 23.95 28.4 23.95Q27.85 23.95 27.175 23.875Q26.5 23.8 25.95 23.6Q27.15 22.35 27.775 20.525Q28.4 18.7 28.4 16.45Q28.4 14.2 27.775 12.475Q27.15 10.75 25.95 9.3Q26.5 9.15 27.175 9.05Q27.85 8.95 28.4 8.95Q31.7 8.95 33.8 11.05Q35.9 13.15 35.9 16.45ZM4.9 37H30.9V35.3Q30.9 34.5 30.425 33.75Q29.95 33 29.25 32.7Q25.65 31.1 23.2 30.55Q20.75 30 17.9 30Q15.05 30 12.575 30.55Q10.1 31.1 6.5 32.7Q5.8 33 5.35 33.75Q4.9 34.5 4.9 35.3ZM17.9 20.95Q19.85 20.95 21.125 19.675Q22.4 18.4 22.4 16.45Q22.4 14.5 21.125 13.225Q19.85 11.95 17.9 11.95Q15.95 11.95 14.675 13.225Q13.4 14.5 13.4 16.45Q13.4 18.4 14.675 19.675Q15.95 20.95 17.9 20.95ZM17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45Q17.9 16.45 17.9 16.45ZM17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Q17.9 30 17.9 30Z"/>
	    </g>
        </SvgIcon>
    );
};



export const MenuContactIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <g>
	        <path d="M7 40Q5.8 40 4.9 39.1Q4 38.2 4 37V11Q4 9.8 4.9 8.9Q5.8 8 7 8H41Q42.2 8 43.1 8.9Q44 9.8 44 11V37Q44 38.2 43.1 39.1Q42.2 40 41 40ZM24 24.9 7 13.75V37Q7 37 7 37Q7 37 7 37H41Q41 37 41 37Q41 37 41 37V13.75ZM24 21.9 40.8 11H7.25ZM7 13.75V11V13.75V37Q7 37 7 37Q7 37 7 37Q7 37 7 37Q7 37 7 37Z"/>
	    </g>
        </SvgIcon>
    );
};



export const MENU_ICON_MAPPING = {
    "MenuActualitesIcon": MenuActualitesIcon,
    "MenuActionIcon": MenuActionIcon,
    "MenuAssociationIcon": MenuAssociationIcon,
    "MenuContactIcon": MenuContactIcon,
}
